.productsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  width: 100%;
  overflow-x: hidden;

  .categoryName {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #404040;
    margin-bottom: 15px;
  }

  .products {
    width: 100%;
    margin-bottom: 35px;

    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
    }

    @media screen and (min-width: 992px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }

    .product {
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row-reverse;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: -1px 25px 32px -9px rgba(15, 15, 15, 0.04);
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        background: #fff;
        transition: all 0.25s;

        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: -1px 25px 32px -9px rgba(15, 15, 15, 0.07);
        }
      }

      @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: row-reverse;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px 15px;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #737373;
        margin-bottom: 15px;
      }

      .description {
        font-size: 12px;
        color: rgba(132, 132, 132, 0.75);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (min-width: 992px) {
          font-size: 12px;
        }
      }

      .image {
        background: transparent;
        height: 115px;
        width: 115px;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        margin: auto 0 auto 30px;

        @media screen and (max-width: 380px) {
          height: 75px;
          width: 75px;
        }

        img {
          object-fit: cover;
        }
      }

      .noImage {
        height: 115px;
        width: 115px;
        background: transparent;
        display: flex;
        align-items: center;
        border-radius: 15px;
        justify-content: center;
        border: 1px solid rgba(112, 112, 112, 0.1);

        @media screen and (max-width: 380px) {
          height: 75px;
          width: 75px;
        }

        svg {
          width: 30px;

          path {
            fill: #aaa;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 15px;
      }

      .fromPrice {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #404040;
        margin-top: 25px;
        flex: 1;
        align-items: flex-end;
        display: flex;

        .price {
          color: #3d734a;
        }

        strike {
          text-decoration: line-through;
          color: #000;
          font-weight: 400;
          opacity: 0.25;
          font-size: 12px;
          line-height: 17px;
        }
      }

      .promotionalPrice {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #3d734a;
        margin-top: 5px;
        flex: 1;
        align-items: center;
        display: flex;
      }
    }
  }
}
