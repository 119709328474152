.background {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }
}

.environmentContainer {
  background: #f0f0f0;
  position: relative;
  z-index: 1;

  &.marginTop {
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
    overflow: hidden;
  }

  @media screen and (min-width: 992px) {
    width: calc(100vw - 5px);
    margin-top: 70px;
  }

  @media screen and (max-width: 991px) {
    &.isDelivery {
      margin-top: 60px;
    }
  }

  &.isDigitalMenu {
    margin-top: 0;
  }

  .storeHeader {
    padding: 25px 15px;
    margin: auto;
    background: #e6e6e6;
    border-radius: 0 0 25px 25px;

    @media screen and (max-width: 991px) {
      padding: 15px;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: 35px;
    }

    .search {
      margin-top: 25px;

      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }

      @media screen and (min-width: 992px) {
        width: 1140px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 15px;
        display: flex;
        align-items: center;
      }
    }
  }

  .container {
    padding: 25px 15px 25px;
    width: 1140px;
    max-width: 100%;
    margin: auto;

    @media screen and (max-width: 991px) {
      padding: 15px;
    }
  }

  .containerB {
    padding: 10px 15px 25px;
    width: 1140px;
    max-width: 100%;
    margin: auto;

    @media screen and (max-width: 991px) {
      padding: 30px 15px 15px;
    }
  }

  .searchAndCategories {
    @media screen and (max-width: 991px) {
      padding-top: 0;
    }

    @media screen and (min-width: 992px) {
      // position: sticky;
      // position: -webkit-sticky;
      // top: 69px;
      // border-bottom: 0.5px solid transparent;
    }

    &.fixed {
      border-color: rgba(0, 0, 0, 0.1);
      background: #fff;
    }

    // .content {
    //   width: 1140px;
    //   max-width: 100%;
    //   display: flex;
    //   align-items: center;
    //   margin: auto;
    //   padding: 0 15px;
    // }

    .search {
      width: 100%;

      @media screen and (min-width: 992px) {
        margin-right: 30px;
      }

      @media screen and (max-width: 991px) {
        border-top: 1px solid #eee;
        padding-top: 20px;
      }
    }

    .categories {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .catalogContainer {
    .emptyCatalog {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 200px;
      text-align: center;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
      }
    }

    .shimmerCatalog {
      width: 100%;
      overflow-x: hidden;
      padding-bottom: 150px;

      .title {
        margin-bottom: 15px;
        width: 200px;

        @media screen and (max-width: 991px) {
          height: 23px;
        }

        @media screen and (min-width: 992px) {
          height: 32px;
        }
      }

      .highlightsDesktop {
        display: grid;
        gap: 15px;
        margin-bottom: 35px;

        @media screen and (max-width: 991px) {
          display: none;
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: 10% 25% 25% 25% 10%;
        }

        .item {
          flex: 1;
          height: 250px;
        }
      }

      .highlightsMobile {
        display: grid;
        gap: 15px;
        margin-bottom: 35px;

        @media screen and (max-width: 991px) {
          grid-template-columns: 25% 50% 25%;

          .item {
            height: 155px;
          }
        }

        @media screen and (min-width: 992px) {
          display: none;
        }

        .item {
          flex: 1;
          height: 250px;
        }
      }

      .products {
        display: grid;

        @media screen and (max-width: 991px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 15px;
        }

        @media screen and (min-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 25px;
        }

        .item {
          flex: 1;
          margin-top: 1px;
          height: 160px;
        }
      }

      .shimmer {
        animation-name: shimmerEffect;
        background: #e9e9e9;
        background-image: linear-gradient(
          to right,
          #e9e9e9 0%,
          #ddd 20%,
          #e9e9e9 40%,
          #e9e9e9 100%
        );
        background-repeat: no-repeat;
        background-size: 915px auto;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        border-radius: 10px;
      }
    }
  }
}

@keyframes shimmerEffect {
  from {
    background-position: -915px 0;
  }
  to {
    background-position: 915px 0;
  }
}

@-webkit-keyframes shimmerEffect {
  from {
    background-position: -915px 0;
  }
  to {
    background-position: 915px 0;
  }
}

.loading {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}


.readQrCode {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100dvh;
  width: 300px;
  margin: auto;

  p {
    font-weight: 600;
    font-size: 14px;
    color: rgba(115, 115, 115, 0.65);
    text-align: center;
  }
}