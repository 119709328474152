.orderTracking {
  background: #fff;
  box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px;

  iframe {
    border: none;
    border-radius: 15px;
    width: 100%;
    min-height: 500px;
  }
}
