.highlights {
  padding: 15px;
  width: 1140px;
  max-width: 100%;
  margin: auto;

  .categoryName {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #404040;
    margin-bottom: 15px;
  }

  .highlight {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 250px;
    border-radius: 10px;
    background: #fff;

    .picture {
      position: relative;
      min-height: 115px;
      border-radius: 10px 10px 0 0;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }

    .details {
      padding: 15px 20px 20px;
      display: flex;
      height: 100%;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: -1px 25px 32px -9px rgba(15, 15, 15, 0.04);
      border-top: none;
      border-radius: 0 0 10px 10px;

      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #737373;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .description {
        font-weight: 500;
        font-size: 12px;
        color: rgba(140, 140, 140, 0.65);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .price {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #3d734a;
      }
    }
  }
}
